import React from "react";

const Homeitems = () => {
  return (
    <>
      <div className="container text-center mb-5" data-aos="fade-up">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="col">
              <img
                src="/assets/Bulb.jpg"
                style={{
                  width: "90%",
                  height: "100%",
                  borderRadius: "4%",
                }}
                className="shadow"
              />
            </div>
          </div>
          <div className="col-md-6" style={{ textAlign: "justify" }}>
            <h2>Feasibility Study</h2>
            You have a vision of a wireless product. So, you know how it should
            look like, what materials will be used, what’s the desired
            connectivity experience, and more. The first task is to find out how
            these requirements affect antenna design – and is your vision
            realistic. Feasibility Study enables you to do just that! The
            feasibility study is the first step of the antenna design journey.
            It verifies that your product plan and connectivity requirements are
            feasible from the antenna design perspective, and identifies the
            wireless protocols (e.g., LTE, NFC, Bluetooth) that can be used to
            achieve the desired RF performance. With our simulation-driven
            process, we can provide you with a comprehensive feasibility study
            faster and more cost-efficiently. A feasibility study is your
            reality check before committing to product development and advancing
            to the next design phase – Antenna Concepting.
          </div>
        </div>
      </div>

      <div className="container text-center mb-5" data-aos="fade-up">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h2 style={{ textAlign: "start" }}>Antenna Concepting</h2>
            <div className="col" style={{ textAlign: "justify" }}>
              You have a vision of a wireless product. So, you know how it
              should look like, what materials will be used, what’s the desired
              connectivity experience, and more. The first task is to find out
              how these requirements affect antenna design – and is your vision
              realistic. Feasibility Study enables you to do just that! The
              feasibility study is the first step of the antenna design journey.
              It verifies that your product plan and connectivity requirements
              are feasible from the antenna design perspective, and identifies
              the wireless protocols (e.g., LTE, NFC, Bluetooth) that can be
              used to achieve the desired RF performance. With our
              simulation-driven process, we can provide you with a comprehensive
              feasibility study faster and more cost-efficiently. A feasibility
              study is your reality check before committing to product
              development and advancing to the next design phase – Antenna
              Concepting.
            </div>
          </div>
          <div className="col">
            <img
              src="/assets/Concept.jpg"
              style={{
                width: "90%",
                height: "100%",
                borderRadius: "4%",
              }}
              className="shadow"
            />
          </div>
        </div>
      </div>

      <div className="container text-center mb-5" data-aos="fade-up">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="col">
              <img
                src="/assets/Detail.jpg"
                style={{
                  width: "90%",
                  height: "100%",
                  borderRadius: "4%",
                }}
                className="shadow"
              />
            </div>
          </div>
          <div className="col-md-6" style={{ textAlign: "justify" }}>
            <h2>Detailed design</h2>
            In Antenna Concepting, you have laid out a rough design framework
            for the antenna. The next step is to select the design approach with
            the highest potential and commence with the actual, detailed Antenna
            Design! The detailed Antenna Design phase involves antenna hardware,
            structure, matching elements, grounding, printed circuit board,
            interfaces between the antenna and other components, and all the
            other critical design items. Optionally, the EMC, EMI, and SIPI
            tests can be carried out, as well as interference elimination. The
            detailed design takes into account the regulations, certifications,
            operator requirements, and, if needed, planning for the second
            source of manufacturing. With the detailed design report, which
            includes the performance report, and detailed CAD drawings, you can
            now place the order for the prototypes! This is the last design
            phase before the prototype is manufactured. That’s why a careful,
            professional, and simulation-aided antenna design is critical –
            testing and amendments are much more expensive and time-consuming
            with the prototype.
          </div>
        </div>
      </div>

      <div className="container text-center mb-5"data-aos="fade-up">
        <div className="row align-items-center">
          <div className="col-md-6" style={{ textAlign: "justify" }}>
            <h2>Prototype verification</h2>
            In Prototype Verification, the RF performance is measured with a
            physical prototype for the first time during the process. On the
            other hand, this is the final step before entering the
            mass-production phase, which is why it is critical – the RF
            performance can still be optimized easily using the prototype. After
            you have manufactured products, it is much more difficult and costly
            to make modifications – and, they compromise the overall product
            quality, or in the worst case, produced batches will have to be
            scrapped. Prototype Verification can be started with a
            non-functional prototype. Software, for instance, is not needed.
            Over-the-air (OTA) measurements such as TRP and TIS can be carried
            out optionally. The purpose of the prototype verification is to
            optimize and fine-tune the antenna performance and make sure the
            product fulfills the regulations, certifications, and operator
            requirements. The matching components are tuned with the final
            settings. As a result, you receive the final verification report
            with an updated design and CAD drawings. You are ready for
            manufacturing!
          </div>
          <div className="col-md-6">
            <div className="col">
              <img
                src="/assets/Prototype.jpg"
                style={{
                  width: "90%",
                  height: "100%",
                  borderRadius: "4%",
                }}
                className="shadow"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container text-center mb-5" data-aos="fade-up">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="col">
              <img
                src="/assets/Clearification.jpg"
                style={{
                  width: "90%",
                  height: "100%",
                  borderRadius: "4%",
                }}
                className="shadow"
              />
            </div>
          </div>
          <div className="col-md-6" style={{ textAlign: "justify" }}>
            <h2>Antenna pre-certification</h2>
            Once you have received the manufactured products, it is time for the
            final, official certification tests. At this point, we recommend you
            conduct Antenna Pre-certification tests. This is to verify that the
            particular unit is fully functional, before sending it to the
            official certification tests. With Antenna Pre-certification, you
            can avoid failing the official test due to a malfunctioning or
            broken device.
          </div>
        </div>
      </div>

      <div className="container text-center mb-5" data-aos="fade-up">
        <div className="row align-items-center">
          <div className="col-md-6" style={{ textAlign: "justify" }}>
            <h2>Manufacturing support</h2>
            When the official certification tests are passed, you are finally
            ready to enter manufacturing! How can Radientum help you here? We
            can support you in many activities related to the antenna to
            smoothen your manufacturing process – e.g., introduce valuable and
            trusted partners, evaluate and validate production line testing, or
            verify the second source.
          </div>
          <div className="col-md-6">
            <div className="col">
              <img
                src="/assets/Manufacture.jpg"
                style={{
                  width: "90%",
                  height: "100%",
                  borderRadius: "4%",
                }}
                className="shadow"
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="container-fluid"
        style={{
          marginTop: "60px",
          height: "80vh",
          backgroundImage: "url('/assets/UI1.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
        }}
      >
        <h1
          className=""
          style={{
            color: "white",
            fontSize: "40px",
            fontWeight: "800",
            textAlign: "center",
            padding:"100px"
          }}
        >
          Empowering the Invisible Pathways of Communication
        </h1>

        
      </div> */}
    </>
  );
};

export default Homeitems;
