import React, { useEffect } from "react"; 
import Homeitems from "./Items";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <>
      <div className="overflow-hidden">
        <div
          className="d-flex container-fluid items-center justify-content-center"
          style={{
            // marginTop: "55px",
            height: "100vh",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            backgroundImage: "url('/assets/Antenaagif.gif')",
          }}
        >
          <div className="row g-0">
            <div className="col-md-4">
              <div
                className="animate__animated animate__fadeInLeft"
                style={{
                  marginTop: "160px",
                  color: "white",
                  textAlign: "start",
                }}
              >
                <h1
                  className=""
                  style={{
                    color: "white",
                    fontSize: "65px",
                    fontWeight: "800",
                  }}
                >
                  {/* Transform, Thrive, Succeed */}
                  Innovation, Empowerment, Growth
                </h1>
                <br />
              </div>
              <h5
                className="animate__animated animate__fadeInLeft"
                style={{
                  color: "white",
                  fontWeight: "700",
                  textAlign: "start",
                }}
              >
                Empowering the Invisible Pathways of Communication
              </h5>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-md-12" data-aos="fade-up">
            <h1
              className="text-center animate__animated animate__fadeInUp"
              style={{
                marginTop: "110px",
                color: "white",
                fontSize: "30px",
                fontWeight: "700",
                color: "red",
              }}
            >
              Why Antenna Design as a Turn-key Service?
            </h1>
            <br />
            <div className="col-md-12">
              <p
                className="mt-3 text-center animate__animated animate__fadeInUp"
                style={{ color: "gray" }}
              >
                Wireless connection is the most critical feature of your
                connected device. And, the antenna is the crucial <br />
                component ensuring robust RF performance and superb
                user-experience. Yet, designing an antenna is <br />
                a demanding process, which affects your time to market and
                product development costs. Radientum’s Antenna <br />
                Design turn-key service fits into your product development
                process – so that you can concentrate on developing <br />a
                superior device while we bring in our world-class antenna design
                expertise, equipment, and facilities.
              </p>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }} data-aos="fade-up">
          <button
            type="button"
            className=" btn rounded rounded-5 mb-5 animate__animated animate__fadeInUp"
            style={{ backgroundColor: "red", color: "white" }}
          >
            REFERENCES
          </button>
        </div>

        <br />
        <Homeitems />
      </div>
    </>
  );
};

export default Home;
