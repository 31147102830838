import React from "react";
import Reitems from "./ReItems";

const References = () => {
  return (
    <div  className="overflow-hidden">
      <div
        className="d-flex container-fluid items-center justify-content-center"
        style={{
          // marginTop: "55px",
          height: "80vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          backgroundImage: "url('/assets/UI2.jpg')",
        }}
      >
        <div className="row g-0">
          <div className="col-md-4">
            <div
              className="animate__animated animate__bounce animate__repeat-2"
              style={{ marginTop: "160px", color: "white", textAlign: "start" }}
            >
              <h1
                className=""
                style={{
                  color: "white",
                  fontSize: "65px",
                  fontWeight: "800",
                }}
              >
                References
              </h1>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-md-12" data-aos="fade-up">
          <h1
            className="text-center animate__animated animate__fadeInUp"
            style={{
              marginTop: "110px",
              color: "white",
              fontSize: "30px",
              fontWeight: "700",
              color: "red",
            }}
          >
            Customer Testimonials
          </h1>
          <br />
        </div>
      </div>
      <div
        id="carouselExampleAutoplaying"
        className="carousel carousel-dark slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div
              className="d-flex cards-wrapper"
              style={{
                gap: "10px",
                width: "calc(100% - 10%)",
                margin: "0 auto",
              }}
            >
              <div className="card p-5" style={{ alignItems: "start", borderColor:"red" }}>
                <span className="text-warning" style={{ fontSize: "1.75em" }}>
                  &#9733;&#9733;&#9733;&#9733;&#9733;
                </span>
                <br />
                <i
                  className="fa-solid fa-quote-left fa-2xl"
                  style={{ color: "lightgray" }}
                ></i>
                <br />
                <p className="card-text">
                  Quick and efficient.
                </p>
                <div className="row row-cols-1 row-cols-md-2 g-1">
                  <div className="col">
                    <i className="fa-solid fa-user fa-3x"></i>
                  </div>
                  <div className="col">
                    <p className="card-title">De Groote</p>
                  </div>
                </div>
              </div>
              <div className="card p-5" style={{ alignItems: "start", borderColor:"red" }}>
                <span className="text-warning" style={{ fontSize: "1.75em" }}>
                  &#9733;&#9733;&#9733;&#9733;&#9733;
                </span>
                <br />
                <i
                  className="fa-solid fa-quote-left fa-2xl"
                  style={{ color: "lightgray" }}
                ></i>
                <br />
                <p className="card-text">
                  Incredibly relible! a pleasure to work with.
                </p>
                <div className="row row-cols-1 row-cols-md-2 g-1">
                  <div className="col">
                    <i className="fa-solid fa-user fa-3x"></i>
                  </div>
                  <div className="col">
                    <p className="card-title">Muhammad Usman</p>
                  </div>
                </div>
              </div>
              <div className="card p-5" style={{ alignItems: "start", borderColor:"red" }}>
                <span className="text-warning" style={{ fontSize: "1.75em" }}>
                  &#9733;&#9733;&#9733;&#9733;&#9733;
                </span>
                <br />
                <i
                  className="fa-solid fa-quote-left fa-2xl"
                  style={{ color: "lightgray" }}
                ></i>
                <br />
                <p className="card-text">
                  Defined field of expertise Clear Commuication to the point delivered what agreed
                </p>
                <div className="row row-cols-1 row-cols-md-2 g-1">
                  <div className="col">
                    <i className="fa-solid fa-user fa-3x"></i>
                  </div>
                  <div className="col">
                    <p className="card-title">Ariane Kunz</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div
              className="d-flex cards-wrapper"
              style={{
                gap: "10px",
                width: "calc(100% - 10%)",
                margin: "0 auto",
              }}
            >
              <div className="card p-5" style={{ alignItems: "start", borderColor:"red" }}>
                <span className="text-warning" style={{ fontSize: "1.75em" }}>
                  &#9733;&#9733;&#9733;&#9733;&#9733;
                </span>
                <br />
                <i
                  className="fa-solid fa-quote-left fa-2xl"
                  style={{ color: "lightgray" }}
                ></i>
                <br />
                <p className="card-text">
                  We're really happy of the work, deliverables and also that you respect deadlines. It is long time collaboration.
                </p>
                <div className="row row-cols-1 row-cols-md-2 g-1">
                  <div className="col">
                    <i className="fa-solid fa-user fa-3x"></i>
                  </div>
                  <div className="col">
                    <p className="card-title">Daanen S</p>
                  </div>
                </div>
              </div>
              <div className="card p-5" style={{ alignItems: "start", borderColor:"red" }}>
                <span className="text-warning" style={{ fontSize: "1.75em" }}>
                  &#9733;&#9733;&#9733;&#9733;&#9733;
                </span>
                <br />
                <i
                  className="fa-solid fa-quote-left fa-2xl"
                  style={{ color: "lightgray" }}
                ></i>
                <br />
                <p className="card-text">
                  Project with Antennatech was a pleasure to work with.
                </p>
                <div className="row row-cols-1 row-cols-md-2 g-1">
                  <div className="col">
                    <i className="fa-solid fa-user fa-3x"></i>
                  </div>
                  <div className="col">
                    <p className="card-title">Muhammad Karim</p>
                  </div>
                </div>
              </div>
              <div className="card p-5" style={{ alignItems: "start", borderColor:"red" }}>
                <span className="text-warning" style={{ fontSize: "1.75em" }}>
                  &#9733;&#9733;&#9733;&#9733;&#9733;
                </span>
                <br />
                <i
                  className="fa-solid fa-quote-left fa-2xl"
                  style={{ color: "lightgray" }}
                ></i>
                <br />
                <p className="card-text">
                  You were able to work with us and our electronics design subcontractor on appropiate level for our knowledge and capabilities
                </p>
                <div className="row row-cols-1 row-cols-md-2 g-1">
                  <div className="col">
                    <i className="fa-solid fa-user fa-3x"></i>
                  </div>
                  <div className="col">
                    <p className="card-title">Omar kaif</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div
              className="d-flex cards-wrapper"
              style={{
                gap: "10px",
                width: "calc(100% - 10%)",
                margin: "0 auto",
              }}
            >
              <div className="card p-5" style={{ alignItems: "start", borderColor:"red" }}>
                <span className="text-warning" style={{ fontSize: "1.75em" }}>
                  &#9733;&#9733;&#9733;&#9733;&#9733;
                </span>
                <br />
                <i
                  className="fa-solid fa-quote-left fa-2xl"
                  style={{ color: "lightgray" }}
                ></i>
                <br />
                <p className="card-text">
                  Antenna design is the most crucial part of a wireless product and it must be designed by professionals like Antennatech.
                </p>
                <div className="row row-cols-1 row-cols-md-2 g-1">
                  <div className="col">
                    <i className="fa-solid fa-user fa-3x"></i>
                  </div>
                  <div className="col">
                    <p className="card-title">Noman Ahmed</p>
                  </div>
                </div>
              </div>
              <div className="card p-5" style={{ alignItems: "start", borderColor:"red" }}>
                <span className="text-warning" style={{ fontSize: "1.75em" }}>
                  &#9733;&#9733;&#9733;&#9733;&#9733;
                </span>
                <br />
                <i
                  className="fa-solid fa-quote-left fa-2xl"
                  style={{ color: "lightgray" }}
                ></i>
                <br />
                <p className="card-text">
                  Antennatech has always done expetional work on our antenna projects.
                </p>
                <div className="row row-cols-1 row-cols-md-2 g-1">
                  <div className="col">
                    <i className="fa-solid fa-user fa-3x"></i>
                  </div>
                  <div className="col">
                    <p className="card-title">Sarah Batool</p>
                  </div>
                </div>
              </div>
              <div className="card p-5" style={{ alignItems: "start", borderColor:"red" }}>
                <span className="text-warning" style={{ fontSize: "1.75em" }}>
                  &#9733;&#9733;&#9733;&#9733;&#9733;
                </span>
                <br />
                <i
                  className="fa-solid fa-quote-left fa-2xl"
                  style={{ color: "lightgray" }}
                ></i>
                <br />
                <p className="card-text">
                  Their designs are top notch. Antennatech is the best.
                </p>
                <div className="row row-cols-1 row-cols-md-2 g-1">
                  <div className="col">
                    <i className="fa-solid fa-user fa-3x"></i>
                  </div>
                  <div className="col">
                    <p className="card-title">Ayesha Khan</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <br />
      <Reitems />
    </div>
  );
};

export default References;
