import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ".././App.css";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  let location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg fixed-top ${
          isScrolled ? "bg-dark" : ""
        }`}
        data-bs-theme="dark"
      >
        <div className="container-fluid">
          <Link
            className={`nav-link text-dark ${
              location.pathname === "/" ? "active" : ""
            }`}
            aria-current="page"
            to="/"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="/assets/Logo.png"
                width="150px"
                height="50px"
                style={{ marginRight: "10px" }}
              />
            </div>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav nav-underline ">
              <li className="nav-item ">
                <Link
                  className={`nav-link text-light ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link text-light ${
                    location.pathname === "/Technology" ? "active" : ""
                  }`}
                  to="/Technology"
                >
                  Technologies
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link text-light ${
                    location.pathname === "/Service" ? "active" : ""
                  }`}
                  to="/Service"
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link text-light ${
                    location.pathname === "/References" ? "active" : ""
                  }`}
                  to="/References"
                >
                  References
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link text-light ${
                    location.pathname === "/Contact" ? "active" : ""
                  }`}
                  to="/Contact"
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Blue line at the bottom */}
        <div
          style={{
            borderBottom: "2px solid",
            color: "red",
            width: "100%",
            position: "absolute",
            bottom: 0,
          }}
        ></div>
      </nav>
    </>
  );
};

export default Navbar;
