import React from "react";

const Reitems = () => {
  return (
    <>
      <div className="row g-0">
        <div className="col-md-12" data-aos="fade-up">
          <h1
            className="text-center animate__animated animate__fadeInUp"
            style={{
              marginTop: "110px",
              color: "white",
              fontSize: "30px",
              fontWeight: "700",
              color: "red",
            }}
          >
            Antenna reference projects
          </h1>
          <br />
          <div className="col-md-12">
            <p
              className="mt-3 text-center animate__animated animate__fadeInUp"
              style={{ color: "gray" }}
            >
              We have designed custom antennas and integrated standard antennas
              for customers ranging <br />
              from startups to multi-national corporations. We have successfully
              delivered more than 200 antenna
              <br />
              and RF projects and helped more than 100 companies in all things
              antenna. Here you can get to know us through <br />
              our past projects and see what customers say about our competence.
              Below are some of our public references.
            </p>
          </div>
        </div>
      </div>

      <div className="container mt-5 mb-5">
  <div className="row row-cols-1 row-cols-md-3 g-4">
    <div className="col">
      <div className="card h-100">
        <img src="/assets/UI2.jpg" className="card-img-top" alt="..." />
        <div className="card-body">
          <h5 className="card-title">Card title</h5>
          <p className="card-text">
            This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
          </p>
        </div>
        <div className="card-footer">
          <small className="text-body-secondary">
            Last updated 3 mins ago
          </small>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card h-100">
        <img src="/assets/UI2.jpg" className="card-img-top" alt="..." />
        <div className="card-body">
          <h5 className="card-title">Card title</h5>
          <p className="card-text">
            This card has supporting text below as a natural lead-in to additional content.
          </p>
        </div>
        <div className="card-footer">
          <small className="text-body-secondary">
            Last updated 3 mins ago
          </small>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card h-100">
        <img src="/assets/UI2.jpg" className="card-img-top" alt="..." />
        <div className="card-body">
          <h5 className="card-title">Card title</h5>
          <p className="card-text">
            This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.
          </p>
        </div>
        <div className="card-footer">
          <small className="text-body-secondary">
            Last updated 3 mins ago
          </small>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  );
};

export default Reitems;
