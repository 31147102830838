import React, { useEffect } from "react"; 
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";


const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <div className="overflow-hidden">
      <div
        className="d-flex container-fluid items-center justify-content-center"
        style={{
          // marginTop: "55px",
          height: "80vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          backgroundImage: "url('/assets/UI4.jpg')",
        }}
      >
        <div className="row g-0">
          <div className="col-md-4">
            <div
              className="animate__animated animate__bounce animate__repeat-2"
              style={{
                marginTop: "160px",
                color: "white",
                textAlign: "start",
              }}
            >
              <h1
                className=""
                style={{
                  color: "white",
                  fontSize: "90px",
                  fontWeight: "800",
                }}
              >
                Services
              </h1>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-md-12" data-aos="fade-up">
          <h1
            className="text-center animate__animated animate__fadeInUp"
            style={{
              marginTop: "110px",
              color: "white",
              fontSize: "30px",
              fontWeight: "700",
              color: "red",
            }}
          >
            Wireless Device Revolution
          </h1>
          <br />
          <div className="container" style={{ width: "70%" }}>
            <div className="col-md-12">
              <p
                className="mt-3 text-center" data-aos="fade-up"
                style={{ color: "gray" }}
              >
                Device makers are launching dozens of new wireless products in
                the markets every day – tens of billions of devices are now
                connected wirelessly. And, we are still in the starting blocks
                of the Internet of Things!
              </p>
            </div>
          </div>
          <div className="container" style={{ width: "70%" }}>
            <div className="col-md-12">
              <p
                className="mt-3 text-center" data-aos="fade-up"
                style={{ color: "gray" }}
              >
                The Internet of Things, cars, and wearables has ignited a
                proliferation of connected devices in all sectors of life,
                business, and the industry. However, you can expect to see
                accelerated growth as device makers are seeking new revenues,
                industries are aiming for higher efficiencies, and consumers are
                craving for faster connections, and more amazing experiences.
                So, ever more wireless devices are required with even better
                connectivity!
              </p>
            </div>
          </div>
          <div className="container" style={{ width: "70%" }}>
            <div className="col-md-12">
              <p
                className="mt-3 text-center" data-aos="fade-up"
                style={{ color: "gray" }}
              >
                However, competition is fierce, and, as you know, it is not easy
                to develop a great wireless device – it’s only as good as the
                quality of the connection. A flaky, dragging wireless connection
                ruins the user-experience in a split moment, the user abandons
                the product, and – in the worst scenario – gives bad online
                ratings, which can simply stop your sales long before the
                break-even point.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-5 mt-5">
        <div className="row row-cols-1 row-cols-md-2 g-2" data-aos="fade-up">
          <div className="col">
          <div className="card h-100 shadow rounded-5">
              <div className="card-body rounded-5 p-5"
                  style={{ backgroundColor: "red" }}>
                <h4 className="card-title" style={{ color: "white" }}>
                  Antenna – the most critical component!
                </h4>
                <p className="card-text mt-5"  style={{ color: "white" }} >
                  It’s not enough to just enable a device with any wireless
                  connection. Today, consumers and industrial users expect
                  connectivity with uncompromised quality. Clearly, wireless has
                  become the most significant feature in consumer gadgets and
                  industrial IoT devices. This, in turn, makes a carefully
                  designed antenna the most critical component in your product!
                </p>
              </div>
            </div>
          </div>
          <div className="col">
          <div className="card h-100 shadow rounded-5">
              <div className="card-body rounded-5 p-5"
                  style={{ backgroundColor: "red" }}>
                <h4 className="card-title" style={{ color: "white" }}>
                  What’s the Product Makers’ Antenna Challenge?
                </h4>
                <p className="card-text mt-5"  style={{ color: "white" }} >
                  As a device maker, how can you ensure that the antenna inside
                  your product delivers superior wireless connectivity and
                  user-experience? Designing an antenna is challenging – how to
                  develop a high-performing antenna without missing out the
                  market window, and blowing up your budget?
                </p>
              </div>
            </div>
          </div>
          <div className="col">
          <div className="card h-100 shadow rounded-5">
              <div className="card-body rounded-5 p-5"
                  style={{ backgroundColor: "red" }}>
                <h4 className="card-title" style={{ color: "white" }}>
                  Hire an Antenna Design Specialist!
                </h4>
                <p className="card-text mt-5"  style={{ color: "white" }} >
                  Building a high-performance antenna for a connected product
                  requires specialist expertise in antenna design, testing,
                  measurements, and simulation. It requires specialist tools,
                  laboratory environments, and anechoic chamber.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 shadow rounded-5">
              <div className="card-body rounded-5 p-5"
                  style={{ backgroundColor: "red" }}>
                <h4 className="card-title" style={{ color: "white" }}>
                  Custom antenna design is our super-power!
                </h4>
                <p className="card-text mt-5"  style={{ color: "white" }} >
                  Since 2015, Radientum has been delivering high-performance
                  antenna design and consulting services for companies startup
                  device makers, and the world-leading brands. Hundreds of
                  products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
            .card:hover {
              transform: scale(
                0.95
              ); /* Increase the scale of the card on hover */
            }
          `}</style>
    </div>
  );
};

export default Services;
