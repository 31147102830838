import React, { useEffect } from "react";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Technologies = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <>
      <div className="overflow-hidden">
        <div
          className="d-flex container-fluid items-center justify-content-center"
          style={{
            // marginTop: "55px",
            height: "80vh",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            backgroundImage: "url('/assets/UI3.jpg')",
          }}
        >
          <div className="row g-0">
            <div className="col-md-4">
              <div
                className="animate__animated animate__bounce animate__repeat-2"
                style={{
                  marginTop: "160px",
                  color: "white",
                  textAlign: "start",
                }}
              >
                <h1
                  className=""
                  style={{
                    color: "white",
                    fontSize: "90px",
                    fontWeight: "800",
                  }}
                >
                  Technologies
                </h1>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-md-12" data-aos="fade-up">
            <h1
              className="text-center animate__animated animate__fadeInUp"
              style={{
                marginTop: "110px",
                color: "white",
                fontSize: "30px",
                fontWeight: "700",
                color: "gray",
              }}
            >
              Wireless technologies
            </h1>
            <br />
            <div
              className="container"
              style={{ width: "70%" }}
              data-aos="fade-up"
            >
              <div className="col-md-12">
                <p className="mt-3 text-center" style={{ color: "gray" }}>
                  We help customers to develop products with superb wireless
                  performance – whatever the technology or product is. We work
                  in all industry sectors that require wireless communication.
                  Our offering covers all steps of product life-cycle; from idea
                  concepting to design, verification and mass production quality
                  control. Our specialty is development and integration of
                  compact antennas to challenging products and environments.
                </p>
              </div>
            </div>
            <div
              className="container"
              style={{ width: "70%" }}
              data-aos="fade-up"
            >
              <div className="col-md-12">
                <p className="mt-3 text-center" style={{ color: "gray" }}>
                  We work with various manufacturing technologies including FPC,
                  LDS, PCB trace and metal structures. In addition, we help our
                  customers to select, customize and integrate antenna
                  components from different antenna suppliers including ceramic
                  chip, PCB, FPC and other antenna types.
                </p>
              </div>
            </div>
            <div
              className="container"
              style={{ width: "70%" }}
              data-aos="fade-up"
            >
              <div className="col-md-12">
                <p className="mt-3 text-center" style={{ color: "gray" }}>
                  In this page you can find more information about specific
                  communication technologies and what we can do for you when
                  those are used in your product.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-5 mt-5" data-aos="fade-up">
          <div className="row row-cols-3 row-cols-md-3 g-4 p-2">
            <div className="col">
              <div className="card h-100 shadow rounded-5">
                <div
                  className="card-body rounded-5 p-5"
                  style={{ backgroundColor: "red" }}
                >
                  <h2 className="card-title" style={{ color: "white" }}>
                    Cellular
                  </h2>
                  <p className="card-text mt-5" style={{ color: "white" }}>
                    We develop integrated multiband antenna solutions for
                    cellular networks that can meet the toughest operator
                    requirements. You can achieve world wide coverage for small
                    or metallic devices
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 shadow rounded-5">
                <div
                  className="card-body rounded-5 p-5"
                  style={{ backgroundColor: "red" }}
                >
                  <h2 className="card-title" style={{ color: "white" }}>
                    IoT
                  </h2>
                  <p className="card-text mt-5" style={{ color: "white" }}>
                    We develop integrated antenna solutions for application that
                    require high performance and low BOM to various IoT
                    technologies including Lora, Sigfox, NB-IoT, Zigbee and many
                    more.
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 shadow rounded-5">
                <div
                  className="card-body rounded-5 p-5"
                  style={{ backgroundColor: "red" }}
                >
                  <h2 className="card-title" style={{ color: "white" }}>
                    Bluetooth
                  </h2>
                  <p className="card-text mt-5" style={{ color: "white" }}>
                    Bluetooth or other 2.4GHz ISM products are often battery
                    powered and small. This means that power handling is
                    critical and high efficiency antenna plays a major role
                    while staying small.
                  </p>
                </div>
              </div>
            </div>
            <div className="col" data-aos="fade-up">
              <div className="card h-100 shadow rounded-5">
                <div
                  className="card-body rounded-5 p-5"
                  style={{ backgroundColor: "red" }}
                >
                  <h2 className="card-title" style={{ color: "white" }}>
                    5G mmWave
                  </h2>
                  <p className="card-text mt-5" style={{ color: "white" }}>
                    Antenna arrays are needed for higher frequency cellular
                    networks with introduction of 5G. We provide custom antenna
                    arrays solutions and product integration for standard
                    antenna modules.
                  </p>
                </div>
              </div>
            </div>
            <div className="col" data-aos="fade-up">
              <div className="card h-100 shadow rounded-5">
                <div
                  className="card-body rounded-5 p-5"
                  style={{ backgroundColor: "red" }}
                >
                  <h2 className="card-title" style={{ color: "white" }}>
                    NFC/RFID
                  </h2>
                  <p className="card-text mt-5" style={{ color: "white" }}>
                    We develop integrated multiband antenna solutions for
                    cellular networks that can meet the toughest operator
                    requirements. You can achieve world wide coverage for small
                    or metallic devices
                  </p>
                </div>
              </div>
            </div>
            <div className="col" data-aos="fade-up">
              <div className="card h-100 shadow rounded-5">
                <div
                  className="card-body rounded-5 p-5"
                  style={{ backgroundColor: "red" }}
                >
                  <h2 className="card-title" style={{ color: "white" }}>
                    Wi-Fi
                  </h2>
                  <p className="card-text mt-5" style={{ color: "white" }}>
                    We develop integrated multiband antenna solutions for
                    cellular networks that can meet the toughest operator
                    requirements. You can achieve world wide coverage for small
                    or metallic devices
                  </p>
                </div>
              </div>
            </div>
            <div className="col" data-aos="fade-up">
              <div className="card h-100 shadow rounded-5">
                <div
                  className="card-body rounded-5 p-5"
                  style={{ backgroundColor: "red" }}
                >
                  <h2 className="card-title" style={{ color: "white" }}>
                    GPS
                  </h2>
                  <p className="card-text mt-5" style={{ color: "white" }}>
                    We develop antennas for all positioning systems such as GPS,
                    Glonass, Beidou etc. No matter if either linear or circular
                    polarization and if single or dual band is needed for your
                    product.
                  </p>
                </div>
              </div>
            </div>
            <div className="col" data-aos="fade-up">
              <div className="card h-100 shadow rounded-5">
                <div
                  className="card-body rounded-5 p-5"
                  style={{ backgroundColor: "red" }}
                >
                  <h2 className="card-title" style={{ color: "white" }}>
                    UWB
                  </h2>
                  <p className="card-text mt-5" style={{ color: "white" }}>
                    We develop high performance high accuracy antennas for UWB
                    data and positioning systems that can meet the toughest
                    requirements. You can achieve excellent performance without
                    sacrificing your products industrial design.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <style jsx>{`
            .card:hover {
              transform: scale(
                1.05
              ); /* Increase the scale of the card on hover */
            }
          `}</style>
        </div>
      </div>
    </>
  );
};

export default Technologies;
