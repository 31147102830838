import React from "react";

const Footer = () => {
  return (
    <>
      <div className="container-fluid bg-dark">
        <div className="container">
          <div className="row align-items-center">
            <div className="col">
              <img
                className=""
                src="/assets/Logo.png"
                
                width="200vw"
                height="80vh"
              />
              <p
                className="mt-3"
                style={{ color: "white", textAlign: "justify" }}
              >
                The nexus of innovation and possibility, a beacon guiding
                businesses toward their digital aspirations. With a focus on
                cutting-edge technologies and personalized services, we empower
                our clients to reach new heights. Let Antenna be your partner in
                navigating the ever-changing landscape of digital transformation
              </p>
              {/* <small className="mt-3" style={{ color: "white" }}>
                Where Ideas Come to Life In The World Of Apps
              </small> */}
            </div>
            <div className="col">
              <h1 className="mt-5" style={{ color: "white" }}>
                Contact us
              </h1>

              <div className="row">
                <p className="">
                  <i
                    className="fa-solid fa-location-dot"
                    style={{ color: "white" }}
                  ></i>
                  <span className="mx-3" style={{ color: "white" }}>
                    First Floor, National Science and Technology Park, NUST,
                    H-12, Islamabad, Pakistan
                  </span>
                </p>
              </div>
              <div className="row">
                <p className="">
                  <i
                    className="fa-solid fa-envelope"
                    style={{ color: "white" }}
                  ></i>
                  <span className="mx-3" style={{ color: "white" }}>
                    contact@antennatec.com
                  </span>
                </p>
              </div>
              <div className="row">
                <div className="mb-5 ">
                  {/* <div className=""> */}
                  {/* <div className="col"> */}
                  <a href="">
                    <i
                      className="fa-brands fa-facebook fa-2xl mt-5 mx-3"
                      style={{ color: "#85878a" }}
                    ></i>
                  </a>
                  {/* </div> */}
                  {/* <div className="col"> */}

                  <a href="">
                    <i
                      className="fa-brands fa-instagram fa-2xl mx-3 my-3 mt-5"
                      style={{ color: "#85878a" }}
                    ></i>
                  </a>
                  {/* </div>
                        <div className="col"> */}

                  <a href="">
                    <i
                      className="fa-brands fa-linkedin fa-2xl mx-3 mt-5"
                      style={{ color: "#85878a" }}
                    ></i>
                  </a>
                  {/* </div>
                        <div className="col"> */}

                  <a href="">
                    <i
                      className="fa-brands fa-youtube fa-2xl mx-3 mt-5"
                      style={{ color: "#85878a" }}
                    ></i>
                  </a>
                  {/* </div> */}
                  {/* <div className="col"> */}

                  <a href="">
                    <i
                      className="fa-brands fa-apple fa-2xl mx-3 mt-5"
                      style={{ color: "#85878a" }}
                    ></i>
                  </a>
                  {/* </div> */}
                  {/* <div className="col"> */}

                  <a href="">
                    <i
                      className="fa-brands fa-google-play fa-2xl mx-3 mt-5"
                      style={{ color: "#85878a" }}
                    ></i>
                  </a>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      <footer className="bg-dark text-center py-3" style={{ color: "grey" }}>
        <p>&copy; 2024 Antenna. All rights reserved.</p>
      </footer>
    </>
  );
};

export default Footer;
